import React, { createContext, useContext, useMemo, ReactNode } from 'react';
import { useApi, featureFlagsApiRef } from '@backstage/core-plugin-api';

import { FeatureFlagsApi } from '@backstage/core-plugin-api';

type FeatureEnabledForEntityContextType = {
  featureFlagApi: FeatureFlagsApi;
  featureFlagList: any;
};

export const FeatureEnabledForEntityContext =
  createContext<FeatureEnabledForEntityContextType | null>(null);

export const FeatureEnabledForEntityProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const featureFlagApi: FeatureFlagsApi = useApi(featureFlagsApiRef);

  const featureFlagList = useMemo(
    () => featureFlagApi.getRegisteredFlags(),
    [featureFlagApi],
  );

  return (
    <FeatureEnabledForEntityContext.Provider
      value={{ featureFlagApi, featureFlagList }}
    >
      {children}
    </FeatureEnabledForEntityContext.Provider>
  );
};

export const useFeatureEnabledForEntityContext = () => {
  return useContext(FeatureEnabledForEntityContext);
};
