import { Entity } from '@backstage/catalog-model';
import { useFeatureEnabledForEntityContext } from '../../contexts/FeatureEnabledForEntityContext';

type Domain = {
  id: string;
  name: string;
  title: string;
};
type ProductParentSpec = {
  domain: Domain & {
    subDomain: Domain;
  };
  productManager?: string[];
  applications: string[];
  ownerTitle: string;
  owner: string;
};

type UserEntityProductSpec = {
  entityRef: string;
  title: string;
  roles: string[];
  tco: number;
  id: string;
  domain: Domain;
  subdomain: Domain;
};

/**
 * Checks access by comparing entity IDs with feature access IDs.
 *
 * - Returns true if both arrays are empty.
 * - Returns false if entity IDs are empty but feature access IDs are not.
 * - Otherwise, checks for at least one matching ID.
 *
 * @param entityIds - Array of entity IDs.
 * @param featureAccessIds - Array of feature access IDs.
 * @returns True if there is at least one match or both arrays are empty, otherwise false.
 */
const hasMatchingIds = (
  entityIds: string[],
  featureAccessIds: string[],
): boolean => {
  if (entityIds.length === 0 && featureAccessIds.length === 0) {
    return true;
  }
  if (entityIds.length === 0 && featureAccessIds.length > 0) {
    return false;
  }

  const lowerCaseEntityIds = entityIds.map(id => id.toLowerCase());
  const lowerCaseFeatureAccessIds = featureAccessIds.map(id =>
    id.toLowerCase(),
  );

  return lowerCaseEntityIds.some(id => lowerCaseFeatureAccessIds.includes(id));
};

/**
 * Checks if an entity has access to a specific feature based on the provided configuration (Product, Domain, SubDomain).
 *
 * @param entity - The entity object to be checked for access.
 * @param config - A configuration object mapping feature IDs to arrays of access IDs.
 * @param featureId - The ID of the feature to check access for.
 * @returns True if the entity has access to the feature, otherwise false.
 */
export const checkEntityAccess = (
  entity: Entity,
  config: Record<string, string[]>,
  featureId: string,
) => {
  const entityIds: string[] = [];
  const featureAccessIds = config[featureId] || [];

  if (featureAccessIds.length === 0) {
    return true;
  }
  /**
   * Adds domain and subdomain IDs from an entity to the entityIds array.
   */
  const pushDomainAndSubdomainIds = (subEntity: any) => {
    if (subEntity?.domain) {
      entityIds.push(subEntity.domain.id);
      if (subEntity.domain.subDomain) {
        entityIds.push(subEntity.domain.subDomain.id);
      }
    }
    if (subEntity?.subdomain) {
      entityIds.push(subEntity.subdomain.id);
    }
  };

  /**
   * Processes an entity based on its kind and adds relevant IDs to the entityIds array.
   */
  switch (entity.kind) {
    case 'User':
      entityIds.push(entity.metadata.name);
      if (Array.isArray(entity?.spec?.products)) {
        (entity?.spec?.products as UserEntityProductSpec[]).forEach(
          (product: UserEntityProductSpec) => {
            entityIds.push(product.id);
            pushDomainAndSubdomainIds(product);
          },
        );
      }
      break;

    case 'Product':
      entityIds.push(entity.metadata.name);
      pushDomainAndSubdomainIds(entity.spec as ProductParentSpec);
      break;

    case 'Group':
      if (entity?.spec?.type === 'domain') {
        entityIds.push(entity.metadata.name);
      } else if (entity?.spec?.type === 'sub-domain') {
        entityIds.push(entity.metadata.name);
        if (entity?.spec?.parent && entity?.spec?.parent !== '') {
          entityIds.push((entity?.spec?.parent as string)?.split('/')[1]);
        }
      }
      break;

    default:
      break;
  }

  return hasMatchingIds(entityIds, featureAccessIds);
};

/**
 * Hook to manage feature enablement based on feature flags and entity configurations.
 *
 * @param config - A configuration object mapping feature IDs to arrays of enablement IDs.
 * @returns An object containing the checkEnablement function.
 */
export const useIsFeatureEnabledForEntity = (
  config: Record<string, string[]> = {},
) => {
  const { featureFlagApi, featureFlagList } =
    useFeatureEnabledForEntityContext() || {};

  /**
   * Checks if a specific feature is enabled for a given entity.
   *
   * @param featureId - The unique identifier of the feature to check.
   * @param entity - The entity object to be evaluated for feature enablement.
   * @returns True if the feature is enabled for the entity, otherwise false.
   */
  const isFeatureEntityEnabled = (
    featureId: string,
    entity: Entity | undefined,
  ) => {
    const featureFlag = featureFlagList.find(
      (feature: any) => feature.name === featureId,
    );

    if (featureFlag && !featureFlagApi?.isActive(featureId)) {
      return false;
    }

    if (entity) {
      return checkEntityAccess(entity, config, featureId);
    }

    return true;
  };

  return { isFeatureEntityEnabled };
};
