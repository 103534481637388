import { makeStyles } from '@material-ui/core';

export const useLatestActivityStyles = makeStyles<any>(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    [theme.breakpoints.up('xl')]: {
      gridTemplateColumns: '1fr 2fr',
      display: 'grid',
      alignItems: 'center',
      gap: 0,
    },
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '0.5rem',
    padding: '1rem',
  },
  metadata: {
    marginLeft: 'auto',
  },
  icon: {
    marginRight: '0.5rem',
    width: 20,
    height: 20,
  },
  activityType: {
    marginRight: '16px',
  },
  body: {
    display: 'flex',
    alignItems: 'center',
  },
}));
