import { appThemeApiRef, useApi } from '@backstage/core-plugin-api';
import { Banner } from '@lego/plugin-baseplate-admin-common';
import React from 'react';
import { useLocation } from 'react-router-dom';
import useAsync from 'react-use/lib/useAsync';
import { AdminApiRef } from '../../api';
import { DismissableBanner } from './DismissableBanner';

export const Banners = () => {
  const adminApi = useApi(AdminApiRef);
  const appApi = useApi(appThemeApiRef);
  const theme = appApi.getActiveThemeId();
  const { pathname } = useLocation();

  const filterTheme = (banner: Banner) =>
    banner.theme === null || banner.theme === theme;

  const filterPath = (banner: Banner) =>
    banner.pages?.length === 0 ||
    banner.pages === null ||
    banner.pages?.includes(pathname);

  const { value: banners, loading } = useAsync(async () => {
    return await adminApi.getBanners();
  });

  if (loading || !banners) {
    return null;
  }

  return (
    <>
      {banners
        .filter(filterTheme)
        .filter(filterPath)
        .map((banner, index) => (
          <div style={index === 0 ? { marginTop: '4rem' } : {}} key={index}>
            <DismissableBanner banner={banner} id={banner.id} />
          </div>
        ))}
    </>
  );
};
