import {
  BackstageUserIdentity,
  githubAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { useEntity } from '@backstage/plugin-catalog-react';
import { useUserProfile } from '@backstage/plugin-user-settings';
import {
  CommunityMembersResponse,
  TeamsChannelPostsResponse,
} from '@lego/plugin-baseplate-communities-common';
import React, { createContext, useContext, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { githubApiRef } from '../api';
import { communitiesV2ApiRef } from '../apiV2';
import { CommunityEntityV2 } from '../types';

type downloadImageFromGithubType = (
  fileUrl: string,
  repo: string,
  extension?: string,
) => Promise<string>;

export const processImageUrl = async (
  url: string,
  repo: string,
  downloadImageFromGithub: downloadImageFromGithubType,
): Promise<string> => {
  const imageExtensions = [
    '.jpg',
    '.jpeg',
    '.png',
    '.gif',
    '.bmp',
    '.svg',
    '.apng',
    '.avif',
    '.webp',
  ];
  const isRelativeUrl = !/^https?:\/\//i.test(url);
  let imageExtension = '';
  const hasImageExtension = imageExtensions.some(ext => {
    imageExtension = ext;
    return url.endsWith(ext);
  });

  if (isRelativeUrl && hasImageExtension) {
    return await downloadImageFromGithub(url, repo, imageExtension); // Example processing
  }

  if (isRelativeUrl) {
    const urlWithoutDotSlash = url.replace('./', '');
    return `https://github.com/LEGO/${repo}/blob/main/${urlWithoutDotSlash}`;
  }

  return url;
};

export const parseAndChangeImageUrls = async (
  text: string,
  repo: string,
  parseImageUrl: (
    url: string,
    repo: string,
    downloadImageFromGithub: downloadImageFromGithubType,
  ) => Promise<string>,
  downloadImageFromGithub: downloadImageFromGithubType,
): Promise<string> => {
  const imageRegex = /(?<=\]\().*?(?=\))/g;
  const matches = text.match(imageRegex) || [];
  const replacements = await Promise.all(
    matches.map(async url => {
      const processedUrl = await parseImageUrl(
        url,
        repo,
        downloadImageFromGithub,
      );
      return { url, processedUrl };
    }),
  );
  let updatedText = text;
  replacements.forEach(({ url, processedUrl }) => {
    updatedText = updatedText.replace(url, processedUrl);
  });
  return updatedText;
};

export const getMIMEType = (extension?: string): string => {
  switch (extension) {
    case '.jpg':
    case '.jpeg':
      return 'image/jpeg';
    case '.png':
      return 'image/png';
    case '.gif':
      return 'image/gif';
    case '.bmp':
      return 'image/bmp';
    case '.svg':
      return 'image/svg+xml';
    case '.apng':
      return 'image/apng';
    case '.avif':
      return 'image/avif';
    case '.webp':
      return 'image/webp';
    default:
      return '';
  }
};

type CommunityContextType = {
  entity: CommunityEntityV2 | undefined;
  backstageIdentity: BackstageUserIdentity | undefined;
  gitHubReadMeContent: string | null | undefined;
  gitHubReadMeLoading: boolean;
  gitHubReadMeError: Error | undefined;
  isUserMaintainer: boolean;
  communityMembersResponse: CommunityMembersResponse | undefined;
  isUserCommunityMember: boolean;
  membershipUpdateLoading: boolean;
  msTeamsActivity: TeamsChannelPostsResponse | undefined;
  setMembershipUpdateLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isLeaveDialogOpen: boolean;
  setIsLeaveDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const CommunityContext = createContext<CommunityContextType>({
  entity: undefined,
  backstageIdentity: undefined,
  gitHubReadMeContent: undefined,
  gitHubReadMeLoading: false,
  gitHubReadMeError: undefined,
  isUserMaintainer: false,
  communityMembersResponse: undefined,
  isUserCommunityMember: false,
  membershipUpdateLoading: false,
  msTeamsActivity: undefined,
  setMembershipUpdateLoading: () => {},
  isLeaveDialogOpen: false,
  setIsLeaveDialogOpen: () => {},
});

export const useCommunity = () => useContext(CommunityContext);

export const CommunityProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const githubApi = useApi(githubApiRef);
  const authApi = useApi(githubAuthApiRef);
  const communitiesV2Api = useApi(communitiesV2ApiRef);

  const { entity }: { entity: CommunityEntityV2 } = useEntity();
  const { backstageIdentity } = useUserProfile();
  const isUserMaintainer = entity.spec?.owners?.includes(
    backstageIdentity?.userEntityRef as string,
  );

  const [membershipUpdateLoading, setMembershipUpdateLoading] = useState(false);
  const { value: communityMembersResponse } = useAsync(async () => {
    if (entity && entity.metadata.dbId) {
      return await communitiesV2Api.getCommunityMemberEntityRefs(
        entity.metadata.dbId,
      );
    }
    return { members: [] };
  }, [entity, membershipUpdateLoading]);

  const { value: msTeamsActivity } = useAsync(async () => {
    if (entity?.spec?.teamsChannelLink) {
      const teamIdMatch =
        entity?.spec?.teamsChannelLink?.match(/groupId=([^&]*)/);
      const teamId = teamIdMatch ? teamIdMatch[1] : null;
      const channelIdMatch =
        entity?.spec?.teamsChannelLink?.match(/channel\/([^/]*)/);
      const channelId = channelIdMatch ? channelIdMatch[1] : null;

      if (teamId && channelId) {
        return await communitiesV2Api.getTeamsChannelPosts(teamId, channelId);
      }
    }
    return undefined;
  }, [entity]);

  const isUserCommunityMember = !!(
    communityMembersResponse &&
    backstageIdentity &&
    communityMembersResponse.members.includes(backstageIdentity.userEntityRef)
  );
  const [isLeaveDialogOpen, setIsLeaveDialogOpen] = useState(false);

  const downloadImageFromGithub = async (
    fileUrl: string,
    repo: string,
    extension?: string,
  ): Promise<string> => {
    const accessToken = await authApi.getAccessToken(['repo']);
    const content = await githubApi.getRepContent(repo, fileUrl, accessToken);
    const MIMEtype = getMIMEType(extension);
    if (content && content.content) {
      const base64String = content.content.replace(/\n/g, '').replace(/ /g, '');
      return `data:${MIMEtype};base64,${base64String}`;
    }
    return fileUrl;
  };

  useAsync(async () => {
    if (entity.spec && entity.spec.githubRepoLink) {
      const repoLinkParts = entity.spec.githubRepoLink.split('/');
      const repo = repoLinkParts[repoLinkParts.length - 1];
      return await communitiesV2Api.getIssuesCommentsFromGithub('LEGO', repo);
    }
    return null;
  }, [entity]);

  const {
    value: gitHubReadMeContent,
    loading: gitHubReadMeLoading,
    error: gitHubReadMeError,
  } = useAsync(async () => {
    if (entity.spec && entity.spec.githubRepoLink) {
      const repoLinkParts = entity.spec.githubRepoLink.split('/');
      const accessToken = await authApi.getAccessToken();
      const repo = repoLinkParts[repoLinkParts.length - 1];
      const content = await githubApi.getRepContent(
        repo,
        'README.md',
        accessToken,
      );
      const binaryString = atob(content.content);
      const utf8String = decodeURIComponent(escape(binaryString));
      return await parseAndChangeImageUrls(
        utf8String,
        repo,
        processImageUrl,
        downloadImageFromGithub,
      );
    }
    return null;
  }, [entity, githubApi, authApi]);

  return (
    <CommunityContext.Provider
      value={{
        entity,
        backstageIdentity,
        gitHubReadMeContent,
        gitHubReadMeLoading,
        gitHubReadMeError,
        isUserMaintainer,
        communityMembersResponse,
        isUserCommunityMember,
        membershipUpdateLoading,
        msTeamsActivity,
        setMembershipUpdateLoading,
        isLeaveDialogOpen,
        setIsLeaveDialogOpen,
      }}
    >
      {children}
    </CommunityContext.Provider>
  );
};
