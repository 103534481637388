import React from 'react';
import {
  Button,
  Card,
  Icon,
  LinkCard,
} from '@lego/plugin-baseplate-core-components';
import { Grid, Typography } from '@material-ui/core';
import { useCommunity } from '../../hooks';
import { formatDistanceToNow } from 'date-fns';
import { useLatestActivityStyles } from './LatestActivity.styles';

export const LatestActivity = () => {
  const styles = useLatestActivityStyles();
  const { entity, msTeamsActivity } = useCommunity();

  const stripHtml = (html: string): string => {
    const content = new DOMParser().parseFromString(html, 'text/html');
    return content.body.textContent || '';
  };

  const formatDate = (activityDate: string): string => {
    const dateObject = new Date(activityDate);
    return formatDistanceToNow(dateObject, { addSuffix: true });
  };

  return (
    <Card title="Latest activity">
      <Grid style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
        {entity && entity.spec && entity.spec.teamsChannelLink && (
          <>
            {msTeamsActivity &&
              msTeamsActivity.value
                .filter(activity => !activity.deletedDateTime)
                .map(activity => (
                  <Card href={activity.webUrl} padding="none">
                    <div className={styles.container}>
                      <div className={styles.text}>
                        <div>
                          <Typography variant="subtitle2">
                            {activity.subject ??
                              `${stripHtml(activity.body.content).slice(
                                0,
                                150,
                              )} ...`}
                          </Typography>
                        </div>
                        <div className={styles.body}>
                          <img
                            src={`https://www.google.com/s2/favicons?domain=${activity.webUrl}`}
                            alt={activity.subject ?? ''}
                            className={styles.icon}
                          />
                          <Typography
                            variant="body2"
                            color="secondary"
                            className={styles.activityType}
                          >
                            Post
                          </Typography>
                          <Icon
                            icon="message"
                            color="secondary"
                            className={styles.icon}
                          />
                          <Typography variant="body2" color="secondary">
                            {`${
                              activity['replies@odata.count'] ?? '0'
                            } comments`}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="secondary"
                            className={styles.metadata}
                          >
                            {formatDate(activity.createdDateTime ?? '')}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </Card>
                ))}
          </>
        )}
        {entity &&
          entity.metadata.links &&
          entity.metadata.links.map(link => (
            <LinkCard title={link.title} url={link.url} key={link.url} />
          ))}
      </Grid>
      {entity && (
        <Button
          variant="text"
          onClick={() => window.open(entity.spec.teamsChannelLink, '_blank')}
        >
          View all
        </Button>
      )}
    </Card>
  );
};
