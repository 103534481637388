import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
  microsoftAuthApiRef,
} from '@backstage/core-plugin-api';
import { scmAuthApiRef } from '@backstage/integration-react';
import { githubApiRef, GithubClient } from './api';
import { communitiesApiRef } from './api/CommunitiesApi';
import { CommunitiesClient } from './api/CommunitiesClient';
import { communitiesV2ApiRef, CommunitiesV2Client } from './apiV2';
import { rootRouteRef } from './utils/routes';

export const communitiesPlugin = createPlugin({
  id: 'communities',
  featureFlags: [{ name: 'communities' }],
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: githubApiRef,
      deps: {
        configApi: configApiRef,
        scmAuthApi: scmAuthApiRef,
        fetchApi: fetchApiRef,
        discoveryApi: discoveryApiRef,
      },
      factory: ({ configApi, scmAuthApi, fetchApi, discoveryApi }) =>
        new GithubClient({ configApi, scmAuthApi, discoveryApi, fetchApi }),
    }),
    createApiFactory({
      api: communitiesApiRef,
      deps: {
        fetchApi: fetchApiRef,
        discoveryApi: discoveryApiRef,
      },
      factory: ({ fetchApi, discoveryApi }) =>
        new CommunitiesClient({
          discoveryApi,
          fetchApi,
        }),
    }),
    createApiFactory({
      api: communitiesV2ApiRef,
      deps: {
        fetchApi: fetchApiRef,
        discoveryApi: discoveryApiRef,
        microsoftAuth: microsoftAuthApiRef,
      },
      factory: ({ fetchApi, discoveryApi, microsoftAuth }) =>
        new CommunitiesV2Client(discoveryApi, fetchApi, microsoftAuth),
    }),
  ],
});

export const CommunitiesPage: () => JSX.Element = communitiesPlugin.provide(
  createRoutableExtension({
    name: 'CommunitiesExplorer',
    component: () => import('./components/Router').then(m => m.Router),
    mountPoint: rootRouteRef,
  }),
);
